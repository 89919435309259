import { FC, useState } from 'react';

import { Flex, Form, Row, message } from 'antd';
import GoogleAuthButton from 'fetures/GoogleAuthButton';
import MicrosoftAuthButton from 'fetures/MicrosoftAuthButton';
import {
	RegistrationPlanFormStepOne,
	RegistrationPlanFormStepTwo,
} from 'fetures/RegistrationPlanFormSteps';
import { useMutation, useQuery } from 'react-query';
import {
	TAddSubscriptionDetailsPayload,
	addSubscriptionsDetails,
	getPrices,
} from 'shared/api/subscription.service';
import WithLoader from 'shared/ui/WithLoader';

import styles from './RegistrationPlanForm.module.scss';

const RegistrationPlanForm: FC = (): JSX.Element => {
	const [form] = Form.useForm();

	const [formStep, setFormStep] = useState(0);
	const [formStepsData, setFormStepsData] = useState<TAddSubscriptionDetailsPayload>(
		{} as TAddSubscriptionDetailsPayload,
	);

	const { data: prices, isLoading: isLoadingPrices } = useQuery({
		queryFn: () => getPrices(),
		queryKey: ['prices'],
		select: (data) => data.subscriptionPrices,
	});

	const { mutateAsync: addSubscriptionsDetailsAsync, isLoading: isLoadingAddSubscriptionsDetails } =
		useMutation({
			mutationFn: (formValues: TAddSubscriptionDetailsPayload) =>
				addSubscriptionsDetails(formValues),
		});

	const onFinish = async (formValues): Promise<void> => {
		if (formStep === 0) {
			setFormStepsData((prev) => ({ ...prev, ...formValues }));
			setFormStep(1);
		}

		if (formStep === 1) {
			try {
				setFormStepsData((prev) => ({ ...prev, ...formValues }));
				const response = await addSubscriptionsDetailsAsync({ ...formValues, ...formStepsData });
				window.location.href = response.paymentUrl;
			} catch (e) {
				message.error(e?.response?.data?.message || e.message || 'Error!');
			}
		}
	};

	return (
		<WithLoader isLoading={isLoadingPrices}>
			<Form
				requiredMark="optional"
				id="registration-form"
				form={form}
				layout="vertical"
				onFinish={onFinish}
				validateTrigger="onChange"
				initialValues={{ numberOfLicenses: 1 }}
			>
				<Flex justify="center" gap={48} className={styles.container} id="form-container">
					{formStep === 0 && (
						<RegistrationPlanFormStepOne prices={prices}>
							<Row gutter={[24, 24]}>
								{/* <GoogleAuthButton setFormStepsData={setFormStepsData} /> */}
								<MicrosoftAuthButton setFormStepsData={setFormStepsData} />
							</Row>
						</RegistrationPlanFormStepOne>
					)}
					{formStep === 1 && (
						<RegistrationPlanFormStepTwo
							isLoadingAddSubscriptionsDetails={isLoadingAddSubscriptionsDetails}
							setFormStep={setFormStep}
							formStepsData={formStepsData}
							prices={prices}
						/>
					)}
				</Flex>
			</Form>
		</WithLoader>
	);
};

export default RegistrationPlanForm;
