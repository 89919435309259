import { FC, ReactNode } from 'react';

import { Button, Col, Flex, Form, Input, InputNumber, Row, Typography } from 'antd';
import { ReactComponent as CheckIcon } from 'assets/svg/checkList.svg';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { TSubscriptionPricesInner, validateDomainString } from 'shared/api/subscription.service';
import { existsEmailValidateStatus } from 'shared/constants/constants';
import FormContainer from 'shared/ui/FormContainer';

import styles from './RegistrationPlanFormStepOne.module.scss';

const { Title, Text, Paragraph } = Typography;

type TRegistrationPlanFormStepOneProps = {
	children: ReactNode;
	prices: TSubscriptionPricesInner;
};

const RegistrationPlanFormStepOne: FC<TRegistrationPlanFormStepOneProps> = ({
	children,
	prices,
}): JSX.Element => {
	const { mutateAsync: validateDomainStringAsync, isLoading: isLoadingValidation } = useMutation({
		mutationFn: (value) => validateDomainString({ domainPrefix: value }),
	});

	const validateDomain = async (_, value): Promise<void> => {
		if (value) {
			const regex = /^[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*)*$/;

			if (!regex.test(value)) {
				return Promise.reject('Invalid domain prefix');
			}
		}
		return Promise.resolve();
	};

	const validateExistsDomain = async (_, value): Promise<void> => {
		if (!value) return;

		try {
			await validateDomainStringAsync(value);
			return Promise.resolve();
		} catch (error) {
			if (error?.response?.data?.responseStatus === existsEmailValidateStatus) {
				return Promise.reject(new Error('Domain already exists'));
			}
		}
	};

	const validateNumberOfLicenses = (_, value): any => {
		if (value > 99) {
			return Promise.reject('Maximum Number of Licenses is 99');
		}
		return Promise.resolve();
	};

	return (
		<>
			<FormContainer position="left">
				<Flex vertical>
					<Title className={styles.title}>Try free for 7 Days</Title>
					<Text className={classNames(styles.text, styles.text36)}>
						Then as low as <span className={styles.textBold}>${prices.TIER_1 / 100}/month</span>
					</Text>
					<Text className={styles.text}>
						🔥 Subscription fatigue is everywhere, we’re usage based pricing
					</Text>
					<Text className={styles.text}>
						🚀 Save 90% on Corvus Link AI Consulting Tool with AI Voice Chat
					</Text>
					<Text className={classNames(styles.text)}>
						⏰ Only available for the first 100 subscribers
					</Text>
					<Text className={classNames(styles.text, styles.text18)}>
						🌐 FREE custom domain for faster, easier collaboration
					</Text>
					<Text className={classNames(styles.text, styles.textSmall)}>
						Transform your workflow and boost productivity with Corvus Link.
					</Text>
					<Text className={classNames(styles.text, styles.text18, styles.textSmall)}>
						Join the innovation leaders today and experience the difference.
					</Text>
					<Text className={classNames(styles.text, styles.bold)}>Perfect for:</Text>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Strategic Consultancies
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Startups
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Small businesses
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Project teams
						</Flex>
					</Paragraph>
				</Flex>
			</FormContainer>
			<FormContainer position="right">
				<Title className={classNames(styles.title, styles.titleRight)}>
					Sign Up Now and Save Big!
				</Title>

				<Row gutter={[16, 16]}>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="First Name"
							name={['customer', 'firstName']}
							rules={[{ required: true, message: 'Required field', whitespace: true }]}
						>
							<Input placeholder="Enter First Name" />
						</Form.Item>
					</Col>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="Last Name"
							name={['customer', 'lastName']}
							rules={[{ required: true, message: 'Required field', whitespace: true }]}
						>
							<Input placeholder="Enter Last Name" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={[16, 16]}>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="Email"
							name={['customer', 'email']}
							rules={[{ required: true, message: 'Required field', whitespace: true }]}
						>
							<Input type="email" placeholder="Enter Email" />
						</Form.Item>
					</Col>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="Number of Licenses Needed"
							name="numberOfLicenses"
							rules={[
								{ required: true, message: 'Required field' },
								{
									validator: validateNumberOfLicenses,
									message: 'Maximum Number of Licenses is 99',
								},
							]}
						>
							<InputNumber
								type="number"
								min={1}
								placeholder="Enter Number of Licenses"
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<p className={styles.formItemLabel}>Desired Domain Prefix</p>
				<Text className={styles.subLabelText}>
					Provide the domain prefix (<strong>________.corvuslink.com</strong>) you would like.
				</Text>
				<Form.Item
					style={{ marginBottom: 24 }}
					name="domainPrefix"
					rules={[
						{ required: true, message: 'Required field' },
						{ validator: validateDomain, message: 'Invalid domain prefix' },
						{
							validator: validateExistsDomain,
							message: 'Domain already exists',
							validateTrigger: 'onSubmit',
						},
					]}
				>
					<Input
						maxLength={24}
						placeholder="Enter your desired domain prefix, for example: secretsauce.corvuslink.com"
					/>
				</Form.Item>
				<Button
					className={styles.submit}
					loading={isLoadingValidation}
					type="primary"
					form="registration-form"
					htmlType="submit"
				>
					Next
				</Button>
				{children}
			</FormContainer>
		</>
	);
};

export default RegistrationPlanFormStepOne;
