import { FC } from 'react';

import { Checkbox, Flex, Form, Typography } from 'antd';
import { TAddSubscriptionDetailsPayload } from 'shared/api/subscription.service';

import PlanCard from '../PlanCard';

import styles from './PlansCardsContainer.module.scss';

const { Title } = Typography;

type TPlansCardsContainerProps = {
	formStepsData: TAddSubscriptionDetailsPayload;
};

const PlansCardsContainer: FC<TPlansCardsContainerProps> = ({ formStepsData }): JSX.Element => {
	return (
		<Flex gap={24} vertical align="center">
			<Flex justify="flex-start" style={{ width: '100%' }}>
				<Title>
					Select your subscription modules{' '}
					<span className={styles.text}>(as many as you want)</span>
				</Title>
			</Flex>
			<Flex justify="flex-start">
				<Form.Item name="subscriptionPlanTypes">
					<Checkbox.Group style={{ gap: 8 }}>
						<PlanCard
							plan="PLAN"
							text={{ row1: 'For creating, managing,', row2: 'and executing plans' }}
							features={[
								'Plans',
								'Forecast',
								'Action Plan',
								'Storyboard',
								'Diagrams',
								'File Explorer',
							]}
						/>
						<PlanCard
							plan="BUILD"
							text={{ row1: 'For designing operating models', row2: 'managing project portfolios' }}
							features={[
								'Operating Model',
								'Projects',
								'Retrospectives',
								'Storyboard',
								'Diagrams',
								'File Explorer',
							]}
						/>
						<PlanCard
							plan="GROW"
							text={{ row1: 'For documenting processes and', row2: 'improving ways of working' }}
							features={['Optimization', 'Storyboard', 'Diagrams', 'File Explorer']}
						/>
						<PlanCard
							plan="ENTERPRISE"
							text={{
								row1: 'For planning, capability building, and',
								row2: 'growth through multiple workspaces',
							}}
							features={['Everything in Plan, Build, Grow', 'Engagements']}
							formStepsData={formStepsData}
						/>
					</Checkbox.Group>
				</Form.Item>
			</Flex>
		</Flex>
	);
};

export default PlansCardsContainer;
