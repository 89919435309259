import { FC, ReactNode } from 'react';

import { Flex, Modal, ModalProps, Typography } from 'antd';
import classNames from 'classnames';

import styles from './CustomModal.module.scss';

const { Title } = Typography;

type TCustomModal = ModalProps & {
	footer?: ReactNode;
	children: ReactNode;
};

const CustomModal: FC<TCustomModal> = (props): JSX.Element => {
	const { title, footer, children, className } = props;

	return (
		<Modal
			{...props}
			wrapClassName={styles.wrapClassName}
			className={classNames(styles.modal, className)}
			title={
				<Flex justify="center" className={styles.modalTitle}>
					<Title level={3}>{title}</Title>
				</Flex>
			}
			footer={(): ReactNode => (
				<Flex justify="space-between" gap={24} style={{ marginTop: 24 }}>
					{footer}
				</Flex>
			)}
		>
			{children}
		</Modal>
	);
};

export default CustomModal;
