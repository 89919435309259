import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Flex, Form } from 'antd';
import {
	TAddSubscriptionDetailsPayload,
	TSubscriptionPricesInner,
} from 'shared/api/subscription.service';

import styles from './RegistrationPlanFormStepTwo.module.scss';
import AddOnsModalWithButton from './ui/AddOnsModalWithButton';
import PlansCardsContainer from './ui/PlansCardsContainer';
import TiersContainer from './ui/TiersContainer';

type TRegistrationPlanFormStepTwoProps = {
	isLoadingAddSubscriptionsDetails: boolean;
	setFormStep: Dispatch<SetStateAction<number>>;
	formStepsData: TAddSubscriptionDetailsPayload;
	prices: TSubscriptionPricesInner;
};

const RegistrationPlanFormStepTwo: FC<TRegistrationPlanFormStepTwoProps> = ({
	isLoadingAddSubscriptionsDetails,
	setFormStep,
	formStepsData,
	prices,
}): JSX.Element => {
	const subscriptionPlanTypesLength = Form.useWatch('subscriptionPlanTypes')?.length || 0;

	return (
		<>
			<Flex vertical justify="center" align="center" className={styles.container} gap={24}>
				<PlansCardsContainer formStepsData={formStepsData} />
				<TiersContainer prices={prices} />
				<Flex style={{ width: '100%' }} justify="center" gap={24}>
					<Button onClick={(): void => setFormStep(0)} className={styles.button}>
						Back
					</Button>
					<AddOnsModalWithButton
						disabled={subscriptionPlanTypesLength === 0}
						className={styles.button}
						type="primary"
						isLoadingAddSubscriptionsDetails={isLoadingAddSubscriptionsDetails}
						prices={prices}
					/>
				</Flex>
			</Flex>
		</>
	);
};

export default RegistrationPlanFormStepTwo;
