import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Button, Col, Form, message } from 'antd';
import { ReactComponent as MicrosoftIcon } from 'assets/svg/microsoft.svg';
import { useMutation } from 'react-query';
import { createMicrosoftConnection } from 'shared/api/external-auth.service';
import { TAddSubscriptionDetailsPayload } from 'shared/api/subscription.service';

import { handleMicrosoftLogin } from './lib';

export type TMicrosoftAuthButtonProps = {
	setFormStepsData: Dispatch<SetStateAction<TAddSubscriptionDetailsPayload>>;
};

const MicrosoftAuthButton: FC<TMicrosoftAuthButtonProps> = ({ setFormStepsData }): JSX.Element => {
	const form = Form.useFormInstance();

	const { mutateAsync: createMicrosoftConnectionAsync } = useMutation({
		mutationFn: (code: string) => createMicrosoftConnection(code),
	});

	const createMicrosoftConn = async (cade: string): Promise<void> => {
		try {
			const response = await createMicrosoftConnectionAsync(cade);
			form.setFieldsValue({ customer: { ...response } });

			setFormStepsData((prev) => ({ ...prev, customer: { ...response } }));
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		} finally {
			localStorage.removeItem('code');
		}
	};

	useEffect(() => {
		const handleStorageChange = (event: StorageEvent): void => {
			if (event.key === 'code') {
				const authCode = event.newValue;
				if (authCode) {
					createMicrosoftConn(authCode);
				}
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (
		<Col lg={12} md={24} xs={24}>
			<Button onClick={handleMicrosoftLogin} style={{ width: '100%' }} icon={<MicrosoftIcon />}>
				Sign up with Microsoft
			</Button>
		</Col>
	);
};

export default MicrosoftAuthButton;
