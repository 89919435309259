import { FC } from 'react';

import { Button, Checkbox, Flex, Typography } from 'antd';
import { ReactComponent as CheckListIcon } from 'assets/svg/checkListGreen.svg';
import { capitalize } from 'lodash';
import { TAddSubscriptionDetailsPayload } from 'shared/api/subscription.service';

import ContactSalesModalWithButton from '../ContactSalesModalWithButton';

import styles from './PlanCard.module.scss';

type TPlanCardProps = {
	plan: 'PLAN' | 'BUILD' | 'GROW' | 'ENTERPRISE';
	text: { row1: string; row2: string };
	features: string[];
	formStepsData?: TAddSubscriptionDetailsPayload;
};

const { Title, Text } = Typography;
const PlanCard: FC<TPlanCardProps> = ({ plan, text, features, formStepsData }): JSX.Element => {
	return (
		<Flex vertical gap={18} className={styles.container}>
			<Flex vertical gap={18} className={styles.topBlockContainer}>
				<Flex align="center" justify="center" className={styles.titleContainer}>
					{!formStepsData && <Checkbox value={plan} className={styles.checkbox} />}
					<Title className={styles.title}>{capitalize(plan)}</Title>
				</Flex>
				<Flex vertical justify="center" align="center">
					<Text>{text.row1}</Text>
					<Text>{text.row2}</Text>
				</Flex>
				{!!formStepsData && <ContactSalesModalWithButton formStepsData={formStepsData} />}
			</Flex>
			<Flex vertical gap={12} className={styles.bottomBlockContainer}>
				<Title level={4} className={styles.featuresTitle}>
					Features you’ll have:
				</Title>
				{features.map((feature, index) => (
					<Flex gap={8} key={index} align="center">
						<CheckListIcon />
						<Text>{feature}</Text>
					</Flex>
				))}
			</Flex>
		</Flex>
	);
};

export default PlanCard;
