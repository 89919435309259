import axios, { API_PREFIX } from 'shared/configs/axios/axios';

const CONTROLLER_PREFIX = 'subscriptions';

export enum ADD_ONS_VALUES {
	INDUSTRY_REPORTS = 'INDUSTRY_REPORTS',
	PROCESS_REPORTS = 'PROCESS_REPORTS',
}

export enum SUBSCRIPTIONS_PLANS {
	PLAN = 'PLAN',
	BUILD = 'BUILD',
	GROW = 'GROW',
	ENTERPRISE = 'ENTERPRISE',
}

export type TSubscriptionsPlan = keyof typeof SUBSCRIPTIONS_PLANS;

export type TSubscriptionsPlans = TSubscriptionsPlan[];

export type TSubscriptionAddon = keyof typeof ADD_ONS_VALUES;

export type TSubscriptionAddons = TSubscriptionAddon[];

export type TAddSubscriptionDetailsPayload = {
	numberOfLicenses: number;
	domainPrefix: string;
	customer: {
		firstName: string;
		lastName: string;
		email: string;
	};
	subscriptionPlanTypes: TSubscriptionsPlans;
	subscriptionAddonTypes: TSubscriptionAddons;
};

export type TAddSubscriptionDetailsResponse = {
	id: string;
	amount: number;
	paymentUrl: string;
};

export type TSubscriptionPricesInner = {
	INDUSTRY_REPORTS_ADDON: number;
	PROCESS_REPORTS_ADDON: number;
	TIER_1: number;
	TIER_1_TOKEN: number;
	TIER_2: number;
	TIER_2_TOKEN: number;
	TIER_3: number;
	TIER_3_TOKEN: number;
};

export type TSubscriptionPrices = {
	subscriptionPrices: TSubscriptionPricesInner;
};

export const addSubscriptionsDetails = async (
	payload: TAddSubscriptionDetailsPayload,
): Promise<TAddSubscriptionDetailsResponse> => {
	const { data } = await axios.post<TAddSubscriptionDetailsResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}`,
		payload,
	);
	return data;
};

export const contactSales = async (payload: TAddSubscriptionDetailsPayload): Promise<void> => {
	await axios.post<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/enterprise`, payload);
};

export const sendSessionId = async (stripeSessionId: string): Promise<void> => {
	await axios.post<void>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/finalize`,
		{},
		{ params: { stripeSessionId } },
	);
};

export const getPrices = async (): Promise<TSubscriptionPrices> => {
	const { data } = await axios.get<TSubscriptionPrices>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/prices`,
	);

	return data;
};

export const validateDomainString = async (params = {}): Promise<void> => {
	await axios.get<void>(`${API_PREFIX}/${CONTROLLER_PREFIX}/validate-domain`, { params });
};
