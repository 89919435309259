import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Flex, Typography } from 'antd';
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg';
import { corvusRedirectLink } from 'shared/constants/constants';
import CustomModal from 'shared/ui/CustomModal';

const { Text } = Typography;

type TContactSalesModalProps = {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ContactSalesModal: FC<TContactSalesModalProps> = ({
	showModal,
	setShowModal,
}): JSX.Element => {
	const onClose = (): void => {
		setShowModal(false);
	};

	return (
		<CustomModal
			width={448}
			open={showModal}
			onCancel={onClose}
			closable={false}
			maskClosable={false}
			title="Thank you for contacting us!"
			footer={
				<a style={{ width: '100%' }} href={corvusRedirectLink}>
					<Button type="primary" style={{ width: '100%' }}>
						Ok
					</Button>
				</a>
			}
			centered
			destroyOnClose
		>
			<Flex justify="center" align="center" vertical gap={24}>
				<Text style={{ textAlign: 'center' }}>
					Your request was successfully send. We will reach out to you within one business day.
				</Text>
				<MailIcon />
			</Flex>
		</CustomModal>
	);
};

export default ContactSalesModal;
