import { FC, useState } from 'react';

import { Button, message } from 'antd';
import { pick } from 'lodash';
import { useMutation } from 'react-query';
import { TAddSubscriptionDetailsPayload, contactSales } from 'shared/api/subscription.service';

import ContactSalesModal from '../ContactSalesModal';

import styles from './ContactSalesModalWithButton.module.scss';

type TContactSalesModalWithButtonProps = {
	formStepsData: TAddSubscriptionDetailsPayload;
};

const ContactSalesModalWithButton: FC<TContactSalesModalWithButtonProps> = ({
	formStepsData,
}): JSX.Element => {
	const [showModal, setShowModal] = useState(false);

	const { mutateAsync: contactSalesAsync, isLoading } = useMutation({
		mutationFn: (payload: TAddSubscriptionDetailsPayload) => contactSales(payload),
	});

	const handleContactSales = async (): Promise<void> => {
		try {
			const payload = pick(formStepsData, ['numberOfLicenses', 'domainPrefix', 'customer']);
			await contactSalesAsync(payload);
			setShowModal(true);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<>
			<Button
				disabled={isLoading}
				loading={isLoading}
				className={styles.contactSales}
				onClick={handleContactSales}
			>
				Contact Sales
			</Button>
			<ContactSalesModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default ContactSalesModalWithButton;
